.select-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .select-container select {
    padding: 8px 12px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    appearance: none;
  }
  
  .select-container button {
    padding: 8px 16px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    background-color: #4CAF50;
    color: #fff;
    cursor: pointer;
  }
  
  .select-container button:hover {
    background-color: #45a049;
  }  

.exercise-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .exercise-card {
    height:200px;
    width: 150px;
    margin: 10px;
    padding: 10px;
    margin-bottom: 5px;
    text-align: center;
    background-color: #f2f2f2;
    border: 1px solid #ccc;
  }
  
  .exercise-name {
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  .gif-container {
    width: 100%;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  h3 {
    margin-bottom: 5px;
    font-size: 16px;
  }
  
  .exercise-gif {
    max-width: 100%;
    max-height: 100%;
  }

  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .pagination button {
    margin: 0 5px;
    padding: 5px 10px;
    background-color: #f2f2f2;
    border: none;
    cursor: pointer;
  }
  
  .pagination button:hover {
    background-color: #ddd;
  }
  
  .pagination button.active {
    background-color: #bbb;
  }
  